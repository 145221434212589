import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICandidate {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async updateMultiStatus(user, id, status, v_mot_iter_int) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_status", status);
        formData.append("id", id);
        formData.append("v_mot_iter_int", v_mot_iter_int);

        return axios.post(this.getUrlDomain("TwoRecruitUpdStatusCandidatesApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async customersHistory(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitCandidateCustHistApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    
    async initInsertSearchCandidato(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    
    async insertSearchCandidate(search_candidate, user, file) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("hid_load_on_cand", 0);
        formData.append("attachment" ,  file);


        for (var key in search_candidate) {
            formData.append(key, search_candidate[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteSearchCandidate(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditSearchCandidato(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateSearchCandidate(search_candidate, user, file, attach_remove) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("hid_load_on_cand", 0);
        formData.append("hid_attach", 0);
        formData.append("hid_id_res", "");
        formData.append("hid_id_job_apply", 0);
        formData.append("attachment" ,  file);
        formData.append("attach_remove" ,  attach_remove);

        for (var key in search_candidate) {
            formData.append(key, search_candidate[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoCandidato(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateGetInfoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsertStoricoClienti(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitHistoryCustInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertStoricoClienti(storico_cliente, user, id_cand_search) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_cand_search", id_cand_search);

        for (var key in storico_cliente) {
            formData.append(key, storico_cliente[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitHistoryCustInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditStoricoClienti(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitHistoryCustInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateStoricoClienti(storico_cliente, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in storico_cliente) {
            formData.append(key, storico_cliente[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitHistoryCustUpdatetApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteHistoryCustomer(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitHistoryCustDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async prevMail(user, id, id_ads_sel) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
        formData.append("v_id_ads_sel", id_ads_sel);


        return axios.post(this.getUrlDomain("TwoRecruitPreviewMailApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getAds(user) {

        let formData = new FormData();

        formData.append("username", user);
     
        return axios.post(this.getUrlDomain("TwoRecruitGetAdsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendMail(user, id, v_body, v_mail_to, v_id_ads) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
        formData.append("v_body", v_body);
        formData.append("v_mail_to", v_mail_to);
        formData.append("v_id_ads", v_id_ads);
     
        return axios.post(this.getUrlDomain("TwoRecruiSendInviteAdsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfo(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
     
        return axios.post(this.getUrlDomain("TwoRecruitSearchCandidateGetInfoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async createCVCompany(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruiCreateCVCompanyApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async createPdfReport(user, inseritodal, inseritoal, aggiornatodal, aggiornatoal, stato) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("inseritodal", inseritodal);
        formData.append("inseritoal", inseritoal);
        formData.append("aggiornatodal", aggiornatodal);
        formData.append("aggiornatoal", aggiornatoal);
        formData.append("stato", stato);


        return axios.post(this.getUrlDomain("TwoRecruiSearchCandidateReportPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async checkPrivacy(id) {

        let formData = new FormData();

        formData.append("v_id", id);
      
        return axios.post(this.getUrlDomain("TwoRecruiSearchCandidateCheckPrivacy"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async saveDataRow(v_id, v_data, v_esito, v_stato, v_note, v_data_ins, v_ora_ins, v_da_colloquiare, v_iter_interrotto) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_data", v_data);
        formData.append("v_esito", v_esito);
        formData.append("v_stato", v_stato);
        formData.append("v_note", v_note);
        formData.append("v_data_ins", v_data_ins);
        formData.append("v_ora_ins", v_ora_ins);
        formData.append("v_da_colloquiare", v_da_colloquiare);
        formData.append("v_iter_interrotto", v_iter_interrotto);
      
        return axios.post(this.getUrlDomain("TwoRecruitSaveDataRowHistApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getDataRow(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
      
        return axios.post(this.getUrlDomain("TwoRecruitGetDataRowHistApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getNoteEsitoRow(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
      
        return axios.post(this.getUrlDomain("TwoRecruitGetNoteEsitoRowHistApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async saveCustRefHistCand(v_id_cust, v_id_ref, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_id_cust", v_id_cust);
        formData.append("v_id_ref", v_id_ref);
      
        return axios.post(this.getUrlDomain("saveCustRefHistCand"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async getDataRowInsert(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
      
        return axios.post(this.getUrlDomain("TwoRecruitGetDataRowHistInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async insertDataRow(v_id, v_data, v_esito, v_stato, v_note, v_data_ins, v_ora_ins, v_da_colloquiare, v_iter_interotto) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_data", v_data);
        formData.append("v_esito", v_esito);
        formData.append("v_stato", v_stato);
        formData.append("v_note", v_note);
        formData.append("v_data_ins", v_data_ins);
        formData.append("v_ora_ins", v_ora_ins);
        formData.append("v_da_colloquiare", v_da_colloquiare);
        formData.append("v_iter_interotto", v_iter_interotto);
      
        return axios.post(this.getUrlDomain("TwoRecruitSaveDataRowHistInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deleteHistoryCandidateRow(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
      
        return axios.post(this.getUrlDomain("TwoRecruitDelRowHistCandApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getContentDocDocxFile(v_file) {

        let formData = new FormData();

        formData.append("v_file", v_file);
      
        return axios.post(this.getUrlDomain("getContentDocDocxFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteTempFile(v_file) {

        let formData = new FormData();

        formData.append("v_file", v_file);
      
        return axios.post(this.getUrlDomain("deleteTempFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async saveEsitoNote(v_esito, v_note, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_esito", v_esito);
        formData.append("v_note", v_note);
      
        return axios.post(this.getUrlDomain("TwoRecruitSaveNoteEsitoRowHistApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getValuesForCreateDip(v_user) {

        let formData = new FormData();

        formData.append("v_user", v_user);
      
        return axios.post(this.getUrlDomain("getValuesForCreateDip"), formData, { 'Content-Type': 'multipart/form-data' });

    }



}

export default new APICandidate({
    url: "https://services.ebadge.it/public/api/"
})
