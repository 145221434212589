import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIComuni {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);
   
        return axios.post(this.getUrlDomain("TwoRecruitComuniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertComune(comune, user) {

        let formData = new FormData();

        formData.append("username", user);
   
        for (var key in comune) {
            formData.append(key, comune[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitComuniInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("prodotto", prodotto);
   
     
        return axios.post(this.getUrlDomain("TwoRecruitComuniInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateComune(comune, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
        for (var key in comune) {
            formData.append(key, comune[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitComuniUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteComune(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   

        return axios.post(this.getUrlDomain("TwoRecruitComuniDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);
   

        return axios.post(this.getUrlDomain("TwoRecruitComuniInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIComuni ({
    url: "https://services.ebadge.it/public/api/"
})
