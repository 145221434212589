var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center d-flex align-center justify-center",staticStyle:{"max-width":"300px"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/initRicercaCandidati"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/2recr_ricerca.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Ricerca")])]}}])},[_c('span',[_vm._v("Ricerca")])])],1),(_vm.isAdmin == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/annunci"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/2recr_annunci.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Annunci")])]}}],null,false,1706814077)},[_c('span',[_vm._v("Annunci")])])],1):_vm._e(),(_vm.isAdmin == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/candidati"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/2recr_candidati.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Candidati")])]}}],null,false,2539390420)},[_c('span',[_vm._v("Candidati")])])],1):_vm._e(),(_vm.isAdmin == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/CvRicevuti"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/2recr_cv_ricevuti.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("CV Ricevuti")])]}}],null,false,805911396)},[_c('span',[_vm._v("CV Ricevuti")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }