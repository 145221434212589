import { render, staticRenderFns } from "./vediTimesheetClientiFornitori.vue?vue&type=template&id=3aeb2f91&scoped=true&"
import script from "./vediTimesheetClientiFornitori.vue?vue&type=script&lang=js&"
export * from "./vediTimesheetClientiFornitori.vue?vue&type=script&lang=js&"
import style0 from "./vediTimesheetClientiFornitori.vue?vue&type=style&index=0&id=3aeb2f91&scoped=true&lang=css&"
import style1 from "./vediTimesheetClientiFornitori.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aeb2f91",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBottomSheet,VBtn,VCard,VCol,VDatePicker,VDivider,VFlex,VIcon,VImg,VListItem,VListItemAvatar,VListItemTitle,VMenu,VRow,VSheet,VTextField,VToolbar})
