<template>
<div>

    <template>
        <div class="text-center">
            <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                <v-sheet class="text-center" height="100vh">
                    <div class="headerSheetAziende" fixed style="position:fixed;">
                        Aziende
                        <v-btn class='closeSheet' icon @click="manageClose()">
                            <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                        </v-btn>
                        <v-toolbar>
                            <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>
                        </v-toolbar>
                    </div>
                    <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                        close
                    </v-btn> -->
                    <v-divider></v-divider>
                    <div class="spacer" style="min-height:72px;"></div>
                    <v-flex xs12 sm12>
                        <v-card>

                            <v-list-item class="text-left" v-for="(tile,key) in filteredAziende" :key="key" @click="manageClickAziende(tile)">
                                <v-icon aria-hidden="false">mdi-domain</v-icon>
                                <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                            </v-list-item>
                        </v-card>
                    </v-flex>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </template>

</div>
</template>

<script>
//import $ from 'jquery';
import _ from 'lodash';

export default {
    name: "aziende",
    props: {},

    mounted: function () {

        this.setGlobal();
    },
    data() {

        return {

            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openAziende: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

        },

    }
};
</script>
