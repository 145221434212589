import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIPrivacy {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }
    


    async initInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitPrivacyInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertPrivacy(companyid, user, file) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("companyid", companyid);
        formData.append("file", file);


        return axios.post(this.getUrlDomain("TwoRecruitPrivacyInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateRegione(regione, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRegioniUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deletePrivacy(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitPrivacyDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitPrivacyInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIPrivacy({
    url: "https://services.ebadge.it/public/api/"
})
