import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISkills {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    


    async initInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitSkillsInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertSkill(skill, file) {

        let formData = new FormData();

        if (file.files.length > 0)
        {

            formData.append("logo", file.files[0]);

        }


        for (var key in skill) {
            formData.append(key, skill[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSkillsInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitSkillsInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateSkill(skill, id, file, removed) {

        let formData = new FormData();

       // formData.append("username", user);
        formData.append("id", id);

        if (file.files.length > 0)
        {

            formData.append("logo", file.files[0]);

        }

        formData.append("removed", removed);

        for (var key in skill) {
            formData.append(key, skill[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSkillsUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteSkill(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitSkillsDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async cloneSkill(user, ids) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", ids);


        return axios.post(this.getUrlDomain("TwoRecruitSkillsCloneApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APISkills({
    url: "https://services.ebadge.it/public/api/"
})
