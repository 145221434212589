<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">
        <v-row>


            <v-col cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/initRicercaCandidati">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/2recr_ricerca.png"
                                transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">Ricerca</div>
                    </template>
                    <span>Ricerca</span>
                </v-tooltip>

            </v-col>

            <v-col v-if="isAdmin == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/annunci">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/2recr_annunci.png"
                                transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">Annunci</div>
                    </template>
                    <span>Annunci</span>
                </v-tooltip>

            </v-col>


            <v-col v-if="isAdmin == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/candidati">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/2recr_candidati.png"
                                transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">Candidati</div>
                    </template>
                    <span>Candidati</span>
                </v-tooltip>

            </v-col>

            <v-col v-if="isAdmin == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/CvRicevuti">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/2recr_cv_ricevuti.png"
                                transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">CV Ricevuti</div>
                    </template>
                    <span>CV Ricevuti</span>
                </v-tooltip>

            </v-col>




        </v-row>
    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();


        this.checkChangePwd();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        this.isCustSupp = window.$cookies.get("is_cust_supp");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }





        this.loadLogo();

        this.getLastLogin();









    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },

    methods: {


        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "2recruit"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkChangePwd(
                atob(v_token)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "2recruit")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },



    },


    data: () => ({

        isAdmin: 0,
        isSU: 0,
        isCustSupp: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
