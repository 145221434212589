import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIRisorse {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsertRisorsa(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtInitAddRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async insertRisorsa(risorsa, user, chkCable, chkDesktop, chkNotebook, chkSoftwareLicence, chkSmartphone, chkSimAndNumber, chkVeicoloAziendale, chkAltro) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("chkCable", chkCable);
    formData.append("chkDesktop", chkDesktop);
    formData.append("chkNotebook", chkNotebook);
    formData.append("chkSoftwareLicence", chkSoftwareLicence);
    formData.append("chkSmartphone", chkSmartphone);
    formData.append("chkSimAndNumber", chkSimAndNumber);
    formData.append("chkVeicoloAziendale", chkVeicoloAziendale);
    formData.append("chkAltro", chkAltro);    

    for (var key in risorsa) {
      formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditRisorsa(id, user)
  {

    return axios.get(this.getUrlDomain("EmtInitEditRisorsaApi") + "/" + id + "?api=1&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateRisorsa(risorsa, id, user, chkCable, chkDesktop, chkNotebook, chkSoftwareLicence, chkSmartphone, chkSimAndNumber, chkVeicoloAziendale, chkAltro)
  {

    let formData = new FormData();

    formData.append("chkCable", chkCable);
    formData.append("chkDesktop", chkDesktop);
    formData.append("chkNotebook", chkNotebook);
    formData.append("chkSoftwareLicence", chkSoftwareLicence);
    formData.append("chkSmartphone", chkSmartphone);
    formData.append("chkSimAndNumber", chkSimAndNumber);
    formData.append("chkVeicoloAziendale", chkVeicoloAziendale);
    formData.append("chkAltro", chkAltro);

    for ( var key in risorsa ) {
       formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtUpdateRisorsaApi") + "/" + id + "?username=" + user, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteRisorsa(id, user)
  {


    return axios.get(this.getUrlDomain("EmtDeleteRisorsaApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteRisorsaMulti(id, user)
  {


    return axios.get(this.getUrlDomain("EmtDeleteRisorsaMultiApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async pianoOrarioAzienda(id_azienda, user)
  {

    let formData = new FormData();

    formData.append("companyid", id_azienda);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtPianoOrarioRisorsaApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async enableCollaboratore(v_ids, v_contr, v_sede)
  {

    let formData = new FormData();

    formData.append("v_ids", v_ids);
    formData.append("v_contr", v_contr);
    formData.append("v_sede", v_sede);


    return axios.post(this.getUrlDomain("EmtEnableCollaboratoreFrom2Recruit"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async loadDataForEnableCollab(v_ids)
  {

    let formData = new FormData();

    formData.append("v_ids", v_ids);

    return axios.post(this.getUrlDomain("loadDataForEnableCollab"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIRisorse({
  url: "https://services.ebadge.it/public/api/"
})
