<template>
    <v-container class="w-container v-overflow edit_privacy_2recr" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#ff00a3">{{ titolo }}</div>

            </v-col>


        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="privacy.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <span class="files">
                        <label style="margin-left:11px!important;font-size:11px;font-size: 11px;font-weight: bold;color:black">Documento</label>
                        <input type="file" id="input-file-logo" name="logo" accept=".pdf" class="dropify"
                            data-max-width="6610" data-max-height="6200" />
                    </span>

                </v-col>

            </v-row>

        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/2recr_aziende.png" max-width="24" max-height="24" />
                                        
                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>

                            <div style="min-height:170px;height:170px">&nbsp;</div>

                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contdipartimento">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetDipartimento" class="vdipartimentosheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetDipartimento" fixed style="position:fixed;">
                                Dipartimento
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetDipartimento = !sheetDipartimento">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchDipartimento"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredDipartimenti"
                                        :key="item.departmentid" @click="manageClickDipartimenti(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemDipartimento'>{{ item.fld_name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contaziendeclienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziendeClienti" class="vaziendeclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziendeClienti" fixed style="position:fixed;">
                                Aziende Clienti
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetAziendeClienti = !sheetAziendeClienti">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAziendeClienti"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziendeClienti"
                                        :key="item.companyid" @click="manageClickAziendeClienti(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemAziendaCliente'>{{ item.fld_name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contutentiassociati">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetUtentiAssociati" class="vutentiassociatisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetUtentiAssociati" fixed style="position:fixed;">
                                Utenti
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetUtentiAssociati = !sheetUtentiAssociati">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchUtentiAssociati"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredUtentiAssociati"
                                        :key="item.login" @click="manageClickUtentiAssociati(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemUtenteAssociato'>{{ item.full_name_usr }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contpopupferieap">

            <template>
                <modal name="popupFerieAP" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ferie Anno Precedente (hh)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreFerieAP" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFerieAP" id="fldFerieAP"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ferieap" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ferieap" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupferieac">

            <template>
                <modal name="popupFerieAC" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ferie Anno Corrente (hh)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreFerieAC" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFerieAC" id="fldFerieAC"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ferieac" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ferieac" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuppermessiap">

            <template>
                <modal name="popupPermessiAP" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Permessi Anno Precedente (hh)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePermessiAP" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldPermessiAP" id="fldPermessiAP"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-permessiap" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-permessiap" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuppermessiac">

            <template>
                <modal name="popupPermessiAC" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Permessi Anno Corrente (hh)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePermessiAC" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldPermessiAC" id="fldPermessiAC"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-permessiac" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-permessiac" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupproroga">

            <template>
                <modal name="popupProroga" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Proroga</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreProroga" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-proroga" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-proroga" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopuplordomese">

            <template>
                <modal name="popupLordoMese" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Lordo Mese (€)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreLordoMese" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldLordoMese" id="fldLordoMese"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-lordomese" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-lordomese" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupdiaria">

            <template>
                <modal name="popupDiaria" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Diaria (€)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreDiaria" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldDiaria" id="fldDiaria"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-diaria" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-diaria" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuptariffnetthour">

            <template>
                <modal name="popupTariffNet" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Tariffa Netto (€/h)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreTariffaNetta" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldTNetta" id="fldTNetta"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-tnetta" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-tnetta" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupcostgiorn">

            <template>
                <modal name="popupCostGiorn" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Costo Giornaliero (€)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreCostGiorn" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldTNetta" id="fldTNetta"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-cgiorn" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-cgiorn" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupebadgepausa">

            <template>
                <modal name="popupEbadgePausa" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Pausa (MM)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreEbadgePausa" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldEbadgePausa" id="fldEbadgePausa"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ebadgepausa" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ebadgepausa" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contlienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetClienti" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetClienti = !sheetClienti">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchClienti"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                        @click="manageClickClienti(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemCliente'>{{ item.fld_name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopuppenalitaintervallo">

            <template>
                <modal name="popupPenalitaIntervallo" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Intervallo Validità (MM)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePenalitaIntervallo" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldPenalitaIntervallo" id="fldPenalitaIntervallo">
                                            </vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-penalitaintervallo" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-penalitaintervallo" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopuppenalitamm">

            <template>
                <modal name="popupPenalitaMM" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Penalità (MM)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePenalitaMM" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldPenalitaMM" id="fldPenalitaMM">
                                            </vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-penalitamm" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-penalitamm" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopuppianoorario">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetPianoOrario" class="vpianoorariosheet">
                        <v-sheet class="text-center" height="100vh">

                            <div class="headerSheetPianoOrario" fixed style="position:fixed;">
                                Piano Orario
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetPianoOrario = !sheetPianoOrario">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_2recruit.png"
                            max-width="30" max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchPianoOrario"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <div style="display:flex;min-width:100vw;margin-top:14px;">

                                <div class="first_column_piano_orario">&nbsp;</div>
                                <div class="second_column_piano_orario">Codice</div>
                                <div class="third_column_piano_orario">Descrizione</div>
                                <div class="fourth_column_piano_orario">Totale (HH)</div>

                            </div>


                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredPianoOrario"
                                        :key="item.codice" @click="manageClickPianoOrario(item)">

                                        <v-img alt="PianoOrario Logo" class="  " contain
                                            src="@/assets/icons8-orario-80.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemPianoOrarioCodice'>{{ item.codice }}
                                        </v-list-item-title>
                                        <v-list-item-title class='itemPianoOrarioDescrizione'>{{ item.descrizione }}
                                        </v-list-item-title>

                                        <v-list-item-title class='itemPianoOrarioDescrizione'>{{ item.total }}
                                        </v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>

                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contpopupvalticket">

            <template>
                <modal name="popupValTicket" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Valore (€)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreTicket" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldValTicket" id="fldValTicket"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-valticket" contain
                                    src="@/assets/btn_confirm3_2recruit.png" max-width="32" max-height="32"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-valticket" contain
                                    src="@/assets/btn_cancel3_2recruit.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupral">

            <template>
                <modal name="popupRAL" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">RAL</label>

                            <div id="d_ral" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreRAL" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldRAL" id="fldRAL">
                                            </vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ral" contain src="@/assets/btn_confirm3_2recruit.png"
                                    max-width="32" max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ral" contain src="@/assets/btn_cancel3_2recruit.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apirisorse from "../utils/risorse/apirisorse";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apiregioni from "../utils/regioni/apiregioni";
import apiprivacy from "../utils/privacy/apiprivacy";

import {
    bus
} from "../main";

import router from ".././router";

// import Vue from "vue";
// import VModal from 'vue-js-modal';


export default ({
    mounted() {

        /*eslint-disable no-undef*/

        // Vue.use(VModal, {
        //     dialog: true
        // });

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        // this.$root.$children[0].initDivError("#d_ferie_ap", "");


        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.initInsert();

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Privacy";

            this.initEdit();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    // this.validate();

                    this.saveData();

                    break;

                case "btn_back":
                    router.push({
                        path: "/privacy"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('change', '#input-file-logo', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });





            $('body').on('click', '.btn-confirm-ral', function (event) {
                console.log(event);

                pointerVue.ricerca_candidato.ral = pointerVue.valoreRAL;

                pointerVue.$root.$children[0].setFieldActive("#d_ral");

                pointerVue.$modal.hide('popupRAL');

            });

            $('body').on('click', '.btn-cancel-ral', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupRAL');

            });


            $('body').on('click', '#d_ral', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupRAL');

            });



            $('body').on('click', '.cl-del-date-nascita', function (event) {
                console.log(event);


                pointerVue.ricerca_candidato.data_nascita = null;



            });



            $('body').on('ionChange', '.id_portale_lavoro', function (event) {
                console.log(event);

                pointerVue.ricerca_candidato.id_portale_lavoro = this.value;

                setTimeout(function () {

                    pointerVue.setupButtons();

                }, 300);


            });

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '#cont_departmentid', function (event) {
                console.log(event);

                pointerVue.sheetDipartimento = true;

            });

            $('body').on('click', '#cont_companycustomer', function (event) {
                console.log(event);

                pointerVue.sheetAziendeClienti = true;

            });


            $('body').on('click', '#cont_customer_id', function (event) {
                console.log(event);

                pointerVue.sheetClienti = true;

            });


            $('body').on('click', '#cont_login', function (event) {
                console.log(event);

                pointerVue.sheetUtentiAssociati = true;

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });


            $('body').on('ionChange', '.has_ticket', function (event) {
                console.log(event);

                console.log("HAS_TICKET VAL: ", this.value)

                pointerVue.risorsa.has_ticket = this.value;

                if (this.value == "S") {

                    pointerVue.viewValTicket = true;

                    if (pointerVue.risorsa.val_ticket == null) {

                        pointerVue.risorsa.val_ticket = "5,29";

                    }

                    if (pointerVue.risorsa.val_ticket == 0) {

                        pointerVue.risorsa.val_ticket = "5,29";

                    }

                    if (pointerVue.risorsa.costo_giornaliero != null) {

                        //formatValue

                        console.log("COST GIORN: ", parseFloat(pointerVue.formatValue(pointerVue.risorsa.costo_giornaliero)));
                        console.log("VAL TICKET: ", parseFloat(pointerVue.formatValue(pointerVue.risorsa.val_ticket)));


                        // console.log("COST GIORN: ", parseFloat(pointerVue.risorsa.costo_giornaliero));
                        // console.log("VAL TICKET: ", parseFloat(pointerVue.risorsa.val_ticket));


                        pointerVue.risorsa.costo_giornaliero = parseFloat(pointerVue.formatValue(pointerVue.risorsa.costo_giornaliero)) + parseFloat(pointerVue.formatValue(pointerVue.risorsa.val_ticket));


                    }




                }

                if (this.value == "N") {

                    pointerVue.risorsa.costo_giornaliero = parseFloat(pointerVue.formatValue(pointerVue.risorsa.costo_giornaliero)) - parseFloat(pointerVue.formatValue(pointerVue.risorsa.val_ticket));

                    pointerVue.risorsa.val_ticket = null;

                    pointerVue.viewValTicket = false;

                }


            });




            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });


            $('body').on('click', '#d_ferie_ap', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFerieAP');

            });

            $('body').on('click', '.btn-confirm-ferieap', function (event) {
                console.log(event);

                pointerVue.risorsa.ferie_ap = pointerVue.valoreFerieAP;

                pointerVue.$root.$children[0].setFieldActive("#d_ferie_ap");

                pointerVue.$modal.hide('popupFerieAP');

            });

            $('body').on('click', '.btn-cancel-ferieap', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFerieAP');

            });

            $('body').on('click', '#d_ferie_ac', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFerieAC');

            });

            $('body').on('click', '.btn-confirm-ferieac', function (event) {
                console.log(event);

                pointerVue.risorsa.ferie_ac = pointerVue.valoreFerieAC;

                pointerVue.$root.$children[0].setFieldActive("#d_ferie_ac");

                pointerVue.$modal.hide('popupFerieAC');

            });

            $('body').on('click', '.btn-cancel-ferieac', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFerieAC');

            });

            $('body').on('click', '#d_permessi_ap', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPermessiAP');

            });

            $('body').on('click', '.btn-confirm-permessiap', function (event) {
                console.log(event);

                pointerVue.risorsa.permessi_ap = pointerVue.valorePermessiAP;

                pointerVue.$root.$children[0].setFieldActive("#d_permessi_ap");

                pointerVue.$modal.hide('popupPermessiAP');

            });

            $('body').on('click', '.btn-cancel-permessiap', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPermessiAP');

            });

            $('body').on('click', '#d_permessi_ac', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPermessiAC');

            });

            $('body').on('click', '.btn-confirm-permessiac', function (event) {
                console.log(event);

                pointerVue.risorsa.permessi_ac = pointerVue.valorePermessiAC;

                pointerVue.$root.$children[0].setFieldActive("#d_permessi_ac");

                pointerVue.$modal.hide('popupPermessiAC');

            });

            $('body').on('click', '.btn-cancel-permessiac', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPermessiAC');

            });

            $('body').on('click', '#d_proroga', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupProroga');

            });

            $('body').on('click', '.btn-confirm-proroga', function (event) {
                console.log(event);

                pointerVue.risorsa.proroga = pointerVue.valoreProroga;

                pointerVue.$root.$children[0].setFieldActive("#d_proroga");

                pointerVue.$modal.hide('popupProroga');

            });

            $('body').on('click', '.btn-cancel-proroga', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupProroga');

            });

            $('body').on('click', '#d_lordomese', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupLordoMese');

            });

            $('body').on('click', '.btn-confirm-lordomese', function (event) {
                console.log(event);

                pointerVue.risorsa.lordo_mese = pointerVue.valoreLordoMese;

                pointerVue.$root.$children[0].setFieldActive("#d_lordomese");

                pointerVue.$modal.hide('popupLordoMese');

            });

            $('body').on('click', '.btn-cancel-lordomese', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupLordoMese');

            });

            $('body').on('click', '#d_diaria', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupDiaria');

            });

            $('body').on('click', '.btn-confirm-diaria', function (event) {
                console.log(event);

                pointerVue.risorsa.diaria = pointerVue.valoreDiaria;

                pointerVue.$root.$children[0].setFieldActive("#d_diaria");

                pointerVue.$modal.hide('popupDiaria');

            });

            $('body').on('click', '.btn-cancel-diaria', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDiaria');

            });

            $('body').on('click', '#d_tarif_net_h', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupTariffNet');

            });

            $('body').on('click', '.btn-confirm-tnetta', function (event) {
                console.log(event);

                pointerVue.risorsa.tariffa_netto = pointerVue.valoreTariffaNetta;

                pointerVue.$root.$children[0].setFieldActive("#d_tarif_net_h");

                pointerVue.$modal.hide('popupTariffNet');

            });

            $('body').on('click', '.btn-cancel-tnetta', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupTariffNet');

            });

            $('body').on('click', '#d_cost_giorn', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCostGiorn');

            });

            $('body').on('click', '.btn-confirm-cgiorn', function (event) {
                console.log(event);

                pointerVue.risorsa.costo_giornaliero = pointerVue.valoreCostGiorn;

                pointerVue.$root.$children[0].setFieldActive("#d_cost_giorn");

                pointerVue.$modal.hide('popupCostGiorn');

            });

            $('body').on('click', '.btn-cancel-cgiorn', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCostGiorn');

            });

            $('body').on('click', '#d_ebadge_pausa', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupEbadgePausa');

            });

            $('body').on('click', '.btn-confirm-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.risorsa.min_pausa = pointerVue.valoreEbadgePausa;

                pointerVue.$root.$children[0].setFieldActive("#d_ebadge_pausa");

                pointerVue.$modal.hide('popupEbadgePausa');

            });

            $('body').on('click', '.btn-cancel-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupEbadgePausa');

            });

            $('body').on('ionChange', '.is_ebadge', function (event) {
                console.log(event);


                if (this.value == "S") {

                    pointerVue.showRow1 = true;
                    pointerVue.showRow3 = true;
                    pointerVue.showRow4 = true;
                    pointerVue.showRow5 = true;
                    pointerVue.showRow6 = true;

                }

                if (this.value == "N") {

                    pointerVue.showRow1 = false;
                    pointerVue.showRow2 = false;
                    pointerVue.showRow3 = false;
                    pointerVue.showRow4 = false;
                    pointerVue.showRow5 = false;
                    pointerVue.showRow6 = false;


                }


            });


            $('body').on('ionChange', '.idebadge_type_registration', function (event) {
                console.log(event);


                console.log("VAL: ", this.value);

                if ((this.value == 2) || (this.value == 3)) {
                    pointerVue.showRow2 = true;
                }

                if ((this.value != 2) && (this.value != 3)) {
                    pointerVue.showRow2 = false;
                }

            });

            $('body').on('click', '#d_penalita_intervallo', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPenalitaIntervallo');

            });

            $('body').on('click', '.btn-confirm-penalitaintervallo', function (event) {
                console.log(event);

                pointerVue.risorsa.min_margine = pointerVue.valorePenalitaIntervallo;

                pointerVue.$root.$children[0].setFieldActive("#d_penalita_intervallo");

                pointerVue.$modal.hide('popupPenalitaIntervallo');

            });

            $('body').on('click', '.btn-cancel-penalitaintervallo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPenalitaIntervallo');

            });




            $('body').on('click', '#d_penalita_mm', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPenalitaMM');

            });

            $('body').on('click', '.btn-confirm-penalitamm', function (event) {
                console.log(event);

                pointerVue.risorsa.min_penalty = pointerVue.valorePenalitaMM;

                pointerVue.$root.$children[0].setFieldActive("#d_penalita_mm");

                pointerVue.$modal.hide('popupPenalitaMM');

            });

            $('body').on('click', '.btn-cancel-penalitamm', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPenalitaMM');

            });

            $('body').on('click', '#cont_piano_orario', function (event) {
                console.log(event);



                pointerVue.loadPianoOrario($("#companyid").val());



            });


            $('body').on('click', '.cl-del-date-nascita', function (event) {
                console.log(event);


                pointerVue.risorsa.data_nascita = null;



            });

            $('body').on('click', '.cl-del-inizio-rapporto', function (event) {
                console.log(event);

                pointerVue.risorsa.inizio_rapporto = null;

            });

            $('body').on('click', '.cl-del-fine-rapporto', function (event) {
                console.log(event);

                pointerVue.risorsa.fine_rapporto = null;

            });







            $('body').on('click', '#d_val_ticket', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupValTicket');

            });

            $('body').on('click', '.btn-confirm-valticket', function (event) {
                console.log(event);


                console.log("VAL INPUT: ", pointerVue.valoreTicket);

                if (pointerVue.valoreTicket != null) {


                    // aggiorno il costo giornaliero

                    // tolgo il vecchio valore 
                    pointerVue.risorsa.costo_giornaliero = parseFloat(pointerVue.formatValue(pointerVue.risorsa.costo_giornaliero)) - parseFloat(pointerVue.formatValue(pointerVue.risorsa.val_ticket));

                    pointerVue.risorsa.val_ticket = pointerVue.valoreTicket;

                    // aggiungo il nuovo valore
                    pointerVue.risorsa.costo_giornaliero = parseFloat(pointerVue.formatValue(pointerVue.risorsa.costo_giornaliero)) + parseFloat(pointerVue.formatValue(pointerVue.risorsa.val_ticket));



                    pointerVue.$root.$children[0].setFieldActive("#d_val_ticket");

                    pointerVue.$modal.hide('popupValTicket');

                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Valore richiesto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }

            });

            $('body').on('click', '.btn-cancel-valticket', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupValTicket');

            });

            $('body').on('blur', '#nome', function (event) {
                console.log(event);


                pointerVue.setupButtons();


            });

            $('body').on('blur', '#cognome', function (event) {
                console.log(event);


                pointerVue.setupButtons();


            });

            $('body').on('blur', '#cellulare', function (event) {
                console.log(event);


                pointerVue.setupButtons();


            });

            $('body').on('blur', '#mail', function (event) {
                console.log(event);


                pointerVue.setupButtons();


            });



            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

           
            });




        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredAziendeClienti() {

            return _.orderBy(this.aziendeclienti.filter(item => {
                if (!this.searchAziendeClienti) return this.aziendeclienti;
                return (item.fld_name.toLowerCase().includes(this.searchAziendeClienti.toLowerCase()));
            }), 'headline')
        },

        filteredDipartimenti() {

            return _.orderBy(this.dipartimenti.filter(item => {
                if (!this.searchDipartimento) return this.dipartimenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipartimento.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        filteredUtentiAssociati() {

            return _.orderBy(this.utentiassociati.filter(item => {
                if (!this.searchUtentiAssociati) return this.utentiassociati;
                return (item.full_name_usr.toLowerCase().includes(this.searchUtentiAssociati.toLowerCase()));
            }), 'headline')
        },



        filteredClienti() {

            return _.orderBy(this.aziendeclienti.filter(item => {
                if (!this.searchClienti) return this.aziendeclienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },


        filteredPianoOrario() {

            return _.orderBy(this.pianiorario.filter(item => {
                if (!this.searchPianoOrario) return this.pianiorario;
                return (item.descrizione.toLowerCase().includes(this.searchPianoOrario.toLowerCase()));
            }), 'headline')
        },





        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        panel: 0,

        portali: [],

        showOther: false,

        viewValTicket: false,

        valoreRAL: null,

        valCheckCable: "N",
        valCheckDesktop: "N",
        valCheckNotebook: "N",
        valCheckSoftwareLicence: "N",
        valCheckSmartphone: "N",
        valCheckSimAndNumber: "N",
        valCheckVeicoloAziendale: "N",
        valCheckAltro: "N",

        valoreFerieAP: null,
        valoreFerieAC: null,
        valorePermessiAP: null,
        valorePermessiAC: null,
        valoreProroga: null,
        valoreLordoMese: null,
        valoreDiaria: null,
        valoreTariffaNetta: null,
        valoreCostGiorn: null,
        valoreEbadgePausa: null,
        valorePenalitaIntervallo: null,
        valorePenalitaMM: null,

        valoreTicket: null,

        showPicker3: false,
        showPicker4: false,
        showPicker5: false,
        showPicker6: false,
        showPicker7: false,

        showRow1: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        privacy: { companyid: "" },

        dipendenti: [],
        tipidocumento: [],
        tipolaurea: [],
        contratti: [],
        interviste: [],
        tiporiscontro: [],
        tipocontratto: [],
        sedelavorativa: [],
        qualifica: [],
        ebadgetiporegistrazione: [],
        valoripianoorario: [],



        genere: [{
            id: "M",
            description: "Maschio"
        }, {
            id: "F",
            description: "Femmina"
        }],

        situazionefamigliare: [{
            id: "Celibe / Nubile",
            description: "Celibe / Nubile"
        }, {
            id: "Coniugato",
            description: "Coniugato"
        }, {
            id: "Separato",
            description: "Separato"
        }],

        itemPatenteGuida: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        itemAutomunito: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ore: [],

        tipisgravi: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        veicoloaziendale: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        contrattoitaliano: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ticket: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        abilitaebadge: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        vedistraordinari: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtypeqr: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtypeman: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtyperec: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgearrotondamento: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeverificaip: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeinviamail: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        progetticaricaore: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        enablepanalita: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],



        currAzienda: "",
        titolo: "Aggiungi Privacy",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetDipartimento: false,
        searchDipartimento: "",

        sheetTipologie: false,
        searchTipologie: "",

        sheetAziendeClienti: false,
        searchAziendeClienti: "",

        sheetUtentiAssociati: false,
        searchUtentiAssociati: "",

        sheetPianoOrario: false,
        searchPianoOrario: "",


        sheetClienti: false,
        searchClienti: "",

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],
        dipartimenti: [],
        dipartimentiTemp: [],

        aziendeclienti: [],
        aziendeclientiTemp: [],

        utentiassociati: [],
        utentiassociatiTemp: [],

        sediassunzioneTemp: [],
        sediassunzione: [],

        pianiorarioTemp: [],
        pianiorario: [],

    }),

    methods: {



        validateEMail: function () {

            var result;

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            console.log("MAIL: ", $("#mail").val());

            if ($("#mail").val() != undefined) {

                if ($("#mail").val().match(validRegex)) {
                    result = true;
                }
                else {
                    result = false;

                }

            }
            else {
                result = true;

            }


            return result;

        },


        formatValue: function (v) {

            var res = v;

            if (res.toString().indexOf(',') != -1) {

                res = res.replace(".", "");
                res = res.replace(",", ".");


            }


            return res;
        },


        setupGridPianoOrario: function () {


            var pointerVue = this;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [{
                    data: 'codice',
                    orderable: false,
                    name: 'codice',
                    width: '2%'
                },

                {
                    data: 'descrizione',
                    orderable: false,
                    name: 'descrizione',
                    width: '2%'
                },







                ];



                window.table = $('#myTablePianoOrario').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtPianoOrarioApi") + '?company_id=7&username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            var valSearch = $("#myTablePianoOrario_filter input").val();

                            var search = {
                                "value": $("#myTablePianoOrario_filter input").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTablePianoOrario_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTablePianoOrario_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtPianoOrarioApi") + '?company_id=7&username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTablePianoOrario_wrapper .row .col-md-6").first().remove();

                $("#myTablePianoOrario_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                

                </div>
            `);



            });

        },







        onExpansionPanelClick(e) {

            console.log("EVENT: ", e);

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });


                console.log("RICERCA CANDIDATO: ", this.fattura);

                var allegato_salvato = this.ricerca_candidato.cv;

                if (allegato_salvato) {

                    var elements = allegato_salvato.split(".");

                    console.log("ELEMENTS: ", elements);


                    setTimeout(() => {



                        $('.dropify-render').append("<i class='dropify-font-file'></i>");
                        $('.dropify-render').append("<span class='dropify-extension'>" + elements[1] + "</span>");

                        // dropify-font-file
                        $(".dropify-wrapper").addClass("has-preview");
                        $(".dropify-preview").show("slow");

                    }, 100);

                }





            }, 100);


        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },


        loadPianoOrario: async function (id_azienda) {


            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.pianoOrarioAzienda(
                id_azienda,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from pianoOrarioAzienda", res);

                this.pianiorario = res.data.piano_orario;

                that.sheetPianoOrario = true;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );



        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.privacy.companyid = azienda.companyid;

            this.setupButtons();

        },

        manageClickPianoOrario: async function (pianoorario) {

            console.log("PO SEL: ", pianoorario);

            this.sheetPianoOrario = false;

            this.risorsa.codice_piano_orario = pianoorario.codice;



        },


        setPianiOrari: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.pianiorarioTemp);

            var pianiOrari = [];

            for (var i = 0; i < this.pianiorarioTemp.length; i++) {

                if (parseInt(this.pianiorarioTemp[i].companyid) == parseInt(id_azienda)) {

                    pianiOrari.push({
                        codice: this.pianiorarioTemp[i].codice,
                        descrizione: this.pianiorarioTemp[i].descrizione,
                        lunedi: this.pianiorarioTemp[i].lunedi,
                        martedi: this.pianiorarioTemp[i].martedi,
                        mercoledi: this.pianiorarioTemp[i].mercoledi,
                        giovedi: this.pianiorarioTemp[i].giovedi,
                        venerdi: this.pianiorarioTemp[i].venerdi,
                        sabato: this.pianiorarioTemp[i].sabato,
                        domenica: this.pianiorarioTemp[i].domenica,
                        total: parseFloat(this.pianiorarioTemp[i].total).toFixed(2),



                    });

                }

            }

            console.log("PO SEL: ", pianiOrari);

            this.pianiorario = pianiOrari;

        },




        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },

        setAziendeClienti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.aziendeclientiTemp);

            var aziendeClienti = [];

            for (var i = 0; i < this.aziendeclientiTemp.length; i++) {

                if (parseInt(this.aziendeclientiTemp[i].our_company) == parseInt(id_azienda)) {

                    aziendeClienti.push({
                        companyid: this.aziendeclientiTemp[i].companyid,
                        fld_name: this.aziendeclientiTemp[i].fld_name
                    });

                }

            }

            console.log("AZI CLI SEL: ", aziendeClienti);

            this.aziendeclienti = aziendeClienti;

        },

        setUtenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.utentiassociatiTemp);

            var utentiAssociati = [];

            for (var i = 0; i < this.utentiassociatiTemp.length; i++) {

                if (parseInt(this.utentiassociatiTemp[i].idcompany_logo) == parseInt(id_azienda)) {

                    utentiAssociati.push({
                        login: this.utentiassociatiTemp[i].login,
                        full_name_usr: this.utentiassociatiTemp[i].full_name_usr
                    });

                }

            }

            console.log("UT ASS SEL: ", utentiAssociati);

            this.utentiassociati = utentiAssociati;

        },




        setSediAssunzione: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SEDI ASS TMP: ", this.sediassunzioneTemp);

            var sediAssunzione = [];

            for (var i = 0; i < this.sediassunzioneTemp.length; i++) {

                if (parseInt(this.sediassunzioneTemp[i].companyid) == parseInt(id_azienda)) {

                    sediAssunzione.push({
                        idsedeassunzione: this.sediassunzioneTemp[i].idsedeassunzione,
                        descrizione: this.sediassunzioneTemp[i].descrizione
                    });

                }

            }

            console.log("SEDI ASS SEL: ", sediAssunzione);

            this.sediassunzione = sediAssunzione;

        },





        manageClickDipartimenti: async function (dipartimento) {

            console.log("DIP SEL: ", dipartimento);

            this.sheetDipartimento = false;

            this.risorsa.departmentid = dipartimento.departmentid;

        },

        manageClickAziendeClienti: async function (aziendacliente) {

            console.log("AZI CLI SEL: ", aziendacliente);

            this.sheetAziendeClienti = false;

            this.risorsa.companycustomer = aziendacliente.companyid;

        },

        manageClickClienti: async function (aziendacliente) {

            console.log("AZI CLI SEL: ", aziendacliente);

            this.sheetClienti = false;

            this.risorsa.customer_id = aziendacliente.companyid;

        },



        manageClickUtentiAssociati: async function (utente) {

            console.log("UTENTE SEL: ", utente);

            this.sheetUtentiAssociati = false;

            this.risorsa.login = utente.login;

        },

        enableSaveButton: function () {

            var result = 1;

            console.log("PRIVACY: ", this.privacy);

            if (this.privacy.companyid == "") {
                result = 0;
            }

            //console.log("FILE: ", $('#input-file-logo')[0].files.length);

            if (result == 1) {
                if ($('#input-file-logo')[0].files.length == 0) {
                    result = 0;
                }
            }

            if (result == 1) {
               
                console.log("FILE: ", $('#input-file-logo')[0].files[0]);

                var v_file = $('#input-file-logo')[0].files[0];

                if (v_file.type != "application/pdf")
                {
                    result = 0;
                }

            }

            return result;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                var enableSave = this.enableSaveButton();

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-2recruit.png",
                        title: "Salva",
                        width: 30,
                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-2recruit.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35,
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            console.log("RISORSA VAL: ", that.risorsa);


            if (that.$route.params.id == 0) {

                if ($("#nome").val() == undefined) {
                    errore = "Sezione Informazioni Generali campo nome richiesto";
                }
                else {
                    if ($("#nome").val() == "") {
                        errore = "Sezione Informazioni Generali campo nome richiesto";
                    }
                }

                if (errore == "") {
                    if ($("#cognome").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo cognome richiesto";
                    }
                    else {
                        if ($("#cognome").val() == "") {
                            errore = "Sezione Informazioni Generali campo cognome richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#companyid").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo azienda richiesto";
                    }
                    else {
                        if ($("#companyid").val() == "") {
                            errore = "Sezione Informazioni Generali campo azienda richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#departmentid").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo dipartimento richiesto";
                    }
                    else {
                        if ($("#departmentid").val() == "") {
                            errore = "Sezione Informazioni Generali campo dipartimento richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#login").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo utente associato richiesto";
                    }
                    else {
                        if ($("#login").val() == "") {
                            errore = "Sezione Informazioni Generali campo utente associato richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#codicefiscale").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo codice fiscale richiesto";
                    }
                    else {
                        if ($("#codicefiscale").val() == "") {
                            errore = "Sezione Informazioni Generali campo codice fiscale richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#codice_piano_orario").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo piano orario richiesto";
                    }
                    else {
                        if ($("#codice_piano_orario").val() == "") {
                            errore = "Sezione Informazioni Generali campo piano orario richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#nmatricola").val() == undefined) {
                        errore = "Sezione Dipendente campo n. matricola richiesto";
                    }
                    else {
                        if ($("#nmatricola").val() == "") {
                            errore = "Sezione Dipendente campo n. matricola richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#tipo_contratto_impiegato").val() == undefined) {
                        errore = "Sezione Dipendente campo tipo nostro contratto richiesto";
                    }
                    else {
                        if ($("#tipo_contratto_impiegato").val() == "") {
                            errore = "Sezione Dipendente campo tipo nostro contratto richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#idsedeassunzione").val() == undefined) {
                        errore = "Sezione Dipendente campo sede assunzione richiesto";
                    }
                    else {
                        if ($("#idsedeassunzione").val() == "") {
                            errore = "Sezione Dipendente campo sede assunzione richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#idqualifica").val() == undefined) {
                        errore = "Sezione Dipendente campo qualifica richiesto";
                    }
                    else {
                        if ($("#idqualifica").val() == "") {
                            errore = "Sezione Dipendente campo qualifica richiesto";
                        }
                    }
                }


            }


            if (that.$route.params.id > 0) {

                if (that.risorsa.name == null) {
                    errore = "Sezione Informazioni Generali campo nome richiesto";
                }
                else {
                    if (that.risorsa.name == "") {
                        errore = "Sezione Informazioni Generali campo nome richiesto";
                    }
                }

                if (errore == "") {
                    if (that.risorsa.surname == null) {
                        errore = "Sezione Informazioni Generali campo cognome richiesto";
                    }
                    else {
                        if (that.risorsa.surname == "") {
                            errore = "Sezione Informazioni Generali campo cognome richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.companyid == null) {
                        errore = "Sezione Informazioni Generali campo azienda richiesto";
                    }
                    else {
                        if (that.risorsa.companyid == "") {
                            errore = "Sezione Informazioni Generali campo azienda richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.departmentid == null) {
                        errore = "Sezione Informazioni Generali campo dipartimento richiesto";
                    }
                    else {
                        if (that.risorsa.departmentid == "") {
                            errore = "Sezione Informazioni Generali campo dipartimento richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.login == null) {
                        errore = "Sezione Informazioni Generali campo utente associato richiesto";
                    }
                    else {
                        if (that.risorsa.login == "") {
                            errore = "Sezione Informazioni Generali campo utente associato richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.codice_fiscale == null) {
                        errore = "Sezione Informazioni Generali campo codice fiscale richiesto";
                    }
                    else {
                        if (that.risorsa.codice_fiscale == "") {
                            errore = "Sezione Informazioni Generali campo codice fiscale richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.codice_piano_orario == null) {
                        errore = "Sezione Informazioni Generali campo piano orario richiesto";
                    }
                    else {
                        if (that.risorsa.codice_piano_orario == "") {
                            errore = "Sezione Informazioni Generali campo piano orario richiesto";
                        }
                    }
                }


                if (errore == "") {
                    if (that.risorsa.n_matricola == null) {
                        errore = "Sezione Dipendente campo n. matricola richiesto";
                    }
                    else {
                        if (that.risorsa.n_matricola == "") {
                            errore = "Sezione Dipendente campo n. matricola richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.tipo_contratto_impiegato == null) {
                        errore = "Sezione Dipendente campo tipo nostro contratto richiesto";
                    }
                    else {
                        if (that.risorsa.tipo_contratto_impiegato == "") {
                            errore = "Sezione Dipendente campo tipo nostro contratto richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.idsedeassunzione == null) {
                        errore = "Sezione Dipendente campo sede assunzione richiesto";
                    }
                    else {
                        if (that.risorsa.idsedeassunzione == "") {
                            errore = "Sezione Dipendente campo sede assunzione richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if (that.risorsa.idqualifica == null) {
                        errore = "Sezione Dipendente campo qualifica richiesto";
                    }
                    else {
                        if (that.risorsa.idqualifica == "") {
                            errore = "Sezione Dipendente campo qualifica richiesto";
                        }
                    }
                }









            }





            if (errore == "") {

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        initInsert: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprivacy.initInsert(
                v_token,
                "2recruit"

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.company;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEdit: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiregioni.initEdit(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);

                this.aziende = res.data.company;

                this.regione = res.data.ads_regione;

                if (this.regione.companyid != null) {
                    $("#itemCompany").addClass("item-has-value");

                }

                setTimeout(() => {

                    this.setupButtons();


                }, 200);






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {


            var that = this;

            console.log("REGIONE: ", that.regione);

            var v_token = window.$cookies.get("token");

            var id_rec = that.$route.params.id;



            if (id_rec == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apiprivacy.insertPrivacy(
                    that.privacy.companyid,
                    v_token,
                    $('#input-file-logo')[0].files[0]
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertPrivacy", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/privacy"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }

            // if (id_rec > 0) {

            //     console.log("ok");

            //     that.showSpinner = true;
            //     that.$root.$children[0].showProgress = true;




            //     var response2 = await apiregioni.updateRegione(
            //         that.regione,
            //         v_token,
            //         id_rec
            //     ).then((res) => {

            //         console.log(response2);

            //         that.$root.$children[0].showProgress = false;
            //         console.log("res from updateRegione", res);

            //         that.$swal({
            //             icon: "success",
            //             text: "Dati salvati correttamente",
            //             showConfirmButton: false,
            //             timer: 2000
            //         });

            //         setTimeout(() => {

            //             that.dialogMsg = false;
            //             that.setupButtons();

            //             router.push({
            //                 path: "/regioni"
            //             });

            //         }, 200);

            //     }).catch(err => {
            //         that.$root.$children[0].showProgress = false;
            //         console.log(err);
            //         var msg = err.response.data.Error;

            //         that.$swal({
            //             icon: "error",
            //             text: msg,
            //             showConfirmButton: false,
            //             timer: 8000
            //         });
            //         console.log("Errori", "Non è stato possibile salvare i dati");
            //         console.log("response", response);

            //     }


            //     );





            // }

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAziendeClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetDipartimento {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetUtentiAssociati {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPianoOrario {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemAziendaCliente {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.itemDipartimento {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.itemUtenteAssociato {
    margin-left: 15px;
}


.itemPianoOrarioCodice {
    margin-left: 15px;
}

.itemPianoOrarioDescrizione {
    margin-left: 15px;
}



.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}



.edit_privacy_2recr .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.edit_privacy_2recr .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_privacy_2recr .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_privacy_2recr ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}




/* .edit_privacy_2recr .v-label {
    font-size: 1em !important
}

.edit_privacy_2recr .v-input {
    font-size: 10px !important;
    padding: 10px !important;
}

.edit_privacy_2recr ion-select {
    font-size: 10px !important;
} */





.cont_piano_orario #myTablePianoOrario {
    margin-right: 3px;
}

.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_piano_orario th {
    padding-left: 5px !important;
}

.cont_piano_orario ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_piano_orario .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_piano_orario #myTablePianoOrario_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_piano_orario div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_piano_orario #myTablePianoOrario_wrapper {
    padding-top: 0px;
}

.text_title {
    background-color: #ff00a3;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_piano_orario .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.first_column_piano_orario {
    width: 4%;
    text-align: left;
}

.second_column_piano_orario {
    width: 32%;
    text-align: left;
    font-weight: bold;
    font-size: 10px;
}

.third_column_piano_orario {
    width: 32%;
    text-align: left;
    margin-left: -6px;
    font-weight: bold;
    font-size: 10px;
}

.fourth_column_piano_orario {
    margin-left: -5px;
    font-weight: bold;
    font-size: 10px;
}

.cl-del-date-nascita {
    width: 15px;
    transform: translate(413px, -54px);
    cursor: pointer;
}

.cl-del-inizio-rapporto {
    width: 15px;
    cursor: pointer;
    transform: translate(415px, -54px);
}

.cl-del-fine-rapporto {
    width: 15px;
    cursor: pointer;
    transform: translate(415px, -54px);
}


.height-panel-asset {
    height: 381px !important
}


@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_piano_orario .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_piano_orario #myTablePianoOrario {
        /* margin-top:54px; */
    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }


    .first_column_piano_orario {
        width: 19%;
        text-align: left;
    }

    .second_column_piano_orario {
        width: 28%;
        text-align: left;
        font-weight: bold;
        font-size: 10px;
    }

    .third_column_piano_orario {
        width: 28%;
        text-align: left;
        margin-left: -6px;
        font-weight: bold;
        font-size: 10px;
    }

    .fourth_column_piano_orario {
        margin-left: -5px;
        font-weight: bold;
        font-size: 10px;
    }


    .cl-del-date {
        transform: translate(286px, -56px);
    }

    .height-panel-asset {
        height: 381px !important
    }



}


@media screen and (max-width: 1024px) {


    .cont_piano_orario #myTablePianoOrario {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }

    .cont_piano_orario .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_piano_orario #myTablePianoOrario_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_piano_orario .cl_num {
        margin-left: 16px !important;
    }





}
</style>
