import axios from 'axios'


class APIConfInvioMail {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async setConfInvioMail(impostazioni_invio_mail, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in impostazioni_invio_mail) {
      formData.append(key, impostazioni_invio_mail[key]);
    }

    return axios.post(this.getUrlDomain("TwoRecruitSetConfigInvioMail"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getConfInvioMail(user) {


    let formData = new FormData();

    formData.append("username", user);


    return axios.post(this.getUrlDomain("TwoRecruitGetConfigInvioMail"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getConfInvioMailRichieste(user) {


    let formData = new FormData();

    formData.append("username", user);


    return axios.post(this.getUrlDomain("TwoRecruitGetConfigInvioMailRich"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async setConfInvioMailRichieste(impostazioni_invio_mail, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in impostazioni_invio_mail) {
      formData.append(key, impostazioni_invio_mail[key]);
    }

    return axios.post(this.getUrlDomain("TwoRecruitSetConfigInvioMailRich"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIConfInvioMail({
  url: "http://emtool.local/public/api/alertduedate/"
})
