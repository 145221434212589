import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIAds {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async initFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitAdsFiltersApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }
    async initFilterRich(user) {

        let formData = new FormData();

        formData.append("username", user);
  
        
        return axios.post(this.getUrlDomain("TwoRecruitAdsFiltersRichApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async abilitaAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsEnableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }









    async jobMuleInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://jobmule.eu/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleLCInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.lc-service.it/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleAriallInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.ariall.eu/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }


    // async disabilitaAds(id, user) {

    //     let formData = new FormData();

    //     formData.append("username", user);
    //     formData.append("id", id);

    //     return axios.post(this.getUrlDomain("TwoRecruitAdsDisableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    // }


    async disabilitaAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsDisableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }





    
    async jobMuleRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://jobmule.eu/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleLCRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.lc-service.it/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleAriallRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.ariall.eu/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }


    async urgenteAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsUrgentApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async nonUrgenteAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsNotUrgentApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteAds(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async cloneAds(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsCloneApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsertAds(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitAdsInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initInsertRichiesta(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitInitInsertRichiestaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }






    async insertAds(ads, user, skills, v_tipo_abil) {

        let formData = new FormData();

        console.log(user);

        formData.append("skills", skills);
        formData.append("v_tipo_abil", v_tipo_abil);

        for (var key in ads) {
            formData.append(key, ads[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitAdsInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditAds(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitAdsInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateAds(ads, user, skills, id) {

        let formData = new FormData();

        console.log(user);

        formData.append("skills", skills);
        formData.append("id", id);

        for (var key in ads) {
            formData.append(key, ads[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitAdsUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async initInsertClienteInt(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitAdsCustIntInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async saveClienteInt(id, ads, idads) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idads", idads);

        for (var key in ads) {
            formData.append(key, ads[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitAdsCustIntSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditClienteInt(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsCustIntInitUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteClientiInt(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsCustIntDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async insertRichiesta(richiesta) {

        let formData = new FormData();

        for (var key in richiesta) {
            formData.append(key, richiesta[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRichiestaInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async initEditRichiesta(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
     
        return axios.post(this.getUrlDomain("TwoRecruitRichiestaInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async updateRichiesta(richiesta, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in richiesta) {
            formData.append(key, richiesta[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRichiestaUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteRicheste(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
     
        return axios.post(this.getUrlDomain("TwoRecruitRichiestaDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async creaAdsFromRichiesta(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
     
        return axios.post(this.getUrlDomain("TwoRecruitCreateAdsFromRichApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIAds({
    url: "https://services.ebadge.it/public/api/"
})
