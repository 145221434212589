import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProvince {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);
   
        return axios.post(this.getUrlDomain("TwoRecruitProvinceInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertProvincia(provincia, user) {

        let formData = new FormData();

        formData.append("username", user);
   
        for (var key in provincia) {
            formData.append(key, provincia[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitProvinceInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("id", id);
        formData.append("username", user);
        formData.append("prodotto", prodotto);
   
     
        return axios.post(this.getUrlDomain("TwoRecruitProvinceInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateProvincia(provincia, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
        for (var key in provincia) {
            formData.append(key, provincia[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitProvinceUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteProvincia(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   

        return axios.post(this.getUrlDomain("TwoRecruitProvinceDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }
    

    async initFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);
   

        return axios.post(this.getUrlDomain("TwoRecruitProvinceInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



}

export default new APIProvince ({
    url: "https://services.ebadge.it/public/api/"
})
