<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import router from ".././router";

export default {
   

    mounted: async function () {

        window.$cookies.set("ricerca_candidati_filtro_stato", "Non Interrotto e Proposto", "9y");
        window.$cookies.set("ricerca_candidati_filtro_testo", "", "9y");
        window.$cookies.set("ricerca_candidati_filtro_id_regione", "-1", "9y");
        window.$cookies.set("ricerca_candidati_filtro_gestiti", "", "9y");

        router.push({
            path: "/ricercaCandidati"
        });




    },



    data: () => ({
       

    }),

    methods: {

    

    },
};
</script>
